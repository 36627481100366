<template>
 <div class="nav">
   <v-app-bar
     color="rgb(165, 36, 39)"
     dark
   >
       <v-img
         class="mx-2"
         :src="require('../../assets/logo_SSDH_HD_BLANC_PNG.png')"
         max-height="150"
         max-width="150"
         contain
       ></v-img>
     <p class="ma-0">Bienvenue dans votre Espace Utilisateur ! </p>
     <v-spacer/>
     <v-btn
       icon
       @click="logout"
     >
       <v-icon>mdi-export</v-icon>
     </v-btn>
   </v-app-bar>
 </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'NavbarAdmin',
  methods: {
    ...mapActions([
      'user/logout',
    ]),
    logout() {
      this['user/logout']();
      this.$router.push('/');
    },
  },
};
</script>

<style scoped>
.nav{
  width: 100%;
}
</style>
