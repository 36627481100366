<template>
  <v-card elevation="2">
    <v-progress-circular
      v-if="loading"
      :size="50"
      indeterminate color="primary"
    >
    </v-progress-circular>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :page.sync="pagination.page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:item.action="{ item }">
        <v-btn
          icon
          color="rgb(200, 211, 0)"
          @click="downloadFile(item)"
        >
          <v-icon>mdi-upload</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-if="!loading"
        v-model="page"
        :total-visible="8"
        color="rgb(165, 36, 39)"
        :length="pagination.pages"
      ></v-pagination>
    </div>
  </v-card>
</template>

<script>
import { debounce } from 'lodash';
import { mapActions, mapState } from 'vuex';
import { SEARCH_DEBOUNCE_WAIT_TIMER } from '../../utils/constants';
import { DEFAULT_FILE_USER_HEADER } from '../../models/userFileHeader';

const DEFAULT_ROWS_PER_PAGE = 30;
export default {
  name: 'BaseAdminFileList',
  data: () => ({
    page: 1,
    headers: DEFAULT_FILE_USER_HEADER,
    itemsPerPage: DEFAULT_ROWS_PER_PAGE,
    query: {
      itemsPerPage: DEFAULT_ROWS_PER_PAGE,
    },
  }),
  props: {
    items: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions([
      'fileUser/setItems',
      'fileUser/downloadFileUser',
    ]),
    downloadFile(file) {
      this['fileUser/downloadFileUser'](file.id);
    },
    delaySearch: debounce(function search() {
      this.buildQuery();
      this['fileUser/setItems'](this.query);
    }, SEARCH_DEBOUNCE_WAIT_TIMER),
    buildQuery() {
      this.query.page = this.page;
    },
  },
  computed: {
    ...mapState({
      loading: (state) => state.fileUser.loading,
    }),
  },
  watch: {
    page: {
      handler() {
        this.delaySearch();
      },
      deep: true,
    },
  },
  created() {
    this.delaySearch();
  },
};
</script>

<style scoped>

</style>
