<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
    <v-snackbar
      v-model="notice.snackbar"
      :color="notice.color"
      :timeout="notice.timeout"
      right
    >
      {{ notice.text }}
      <v-btn
        dark
        text
        @click="closeNotice()"
      >
        Close
      </v-btn>
    </v-snackbar>
    <v-footer
      class="footer-style"
      fixed
    >
      <p class="pa-0"> {{ new Date().getFullYear() }} — <strong>Cortex</strong></p>
    </v-footer>

  </v-app>
</template>

<script>

import { mapState } from 'vuex';

export default {
  name: 'App',

  data: () => ({

  }),
  computed: {
    ...mapState({
      notice: (state) => state.notice,
    }),
  },
  methods: {
    closeNotice() {
      this.$store.commit('notice/unsetNotice');
    },
  },
};
</script>
<style scoped>
.footer-style {
  display: flex;
  justify-content: center;
}
</style>
