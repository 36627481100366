const FILE_USER_LIST = [
  {
    id: '15dz15',
    locNumber: 154151,
    lastName: 'Dupond',
    firstName: 'Jack',
    address: '35 rue de l\'eglise',
    city: 'Pantin',
    periodAt: 'Octobre',
    action: '',
  },
  {
    id: '5d41z5',
    locNumber: 154151,
    lastName: 'Dupond',
    firstName: 'Jack',
    address: '35 rue de l\'eglise',
    city: 'Pantin',
    periodAt: 'Novembre',
    action: '',
  },
];

export default FILE_USER_LIST;
