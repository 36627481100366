<template>
  <v-container class="pa-0 container" fluid>
    <NavbarAdmin/>
    <v-row class="row-container">
      <v-col cols="12" class="pa-10">
        <base-page-title v-bind:page-title="pageTitle"/>
        <v-row>
          <v-col cols="6" class="pa-6">
            <file-upload
              name="base-customer-item-banking-account-list-upload-csv"
              :extensions="extensionsAccept"
              :accept="accept"
              :directory="directory"
              :multiple=false
              v-model="files"
              :drop=true
              :drop-directory=true
              @input="uploadCsvFile"
              ref="uploadedCsv"
            >
              <v-btn
                large
                color="rgb(200, 211, 0)"
                class="black--text login-btn"
              >
                Import CSV
              </v-btn>
            </file-upload>
          </v-col>
          <v-col cols="6">
          </v-col>
        </v-row>
       <base-admin-customer-list
         :items="items"
         :pagination="pagination"
         :loading="loading"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import NavbarAdmin from '../components/admin/NavbarAdmin.vue';
import BasePageTitle from '../components/BasePageTitle.vue';
import BaseAdminCustomerList from '../components/admin/BaseAdminCustomerList.vue';

export default {
  name: 'AdminHome',
  components: { BaseAdminCustomerList, BasePageTitle, NavbarAdmin },
  data: () => ({
    pageTitle: 'Liste des utilisateurs',
    extensionsAccept: ['csv'],
    mimeTypesAccepts: ['text/csv', 'text/plain', 'application/vnd.ms-excel'],
    directory: false,
    files: [],
  }),
  computed: {
    ...mapState({
      items: (state) => state.customer.items,
      pagination: (state) => state.customer.pagination,
      loading: (state) => state.customer.loading,
    }),
    accept() {
      return this.concatWithColon();
    },
  },
  methods: {
    ...mapActions([
      'customer/setItems',
      'customer/sendCsv',
    ]),
    concatWithColon() {
      return this.mimeTypesAccepts.length
        ? this.mimeTypesAccepts.reduce((accumulator, currentValue) => `${accumulator},${currentValue}`)
        : null;
    },
    uploadCsvFile() {
      this['customer/sendCsv'](this.files[0]);
      // this.$router.go(n);
    },
  },
};
</script>

<style scoped>
.container {
  height: 60vh;
}
.row-container{
  height: 95vh;
}
</style>
