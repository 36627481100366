export const DEFAULT_CUSTOMER_HEADER = [
  {
    text: 'N°',
    sortable: false,
    value: 'id',
  },
  {
    text: 'identifiant',
    value: 'userName',
    sortable: false,
  },
  {
    text: 'Code utilisateur',
    value: 'userCode',
    sortable: false,
  },
  {
    text: 'Prénom',
    value: 'firstName',
    sortable: false,
  },
  {
    text: 'Nom',
    value: 'lastName',
    sortable: false,
  },
  {
    text: 'Email',
    value: 'email',
    sortable: false,
  },
];
