import DEFAULT_FILE_USER_HEADER from '../../models/userFileHeader';
import api from '../../utils/api';
import { commitPagination } from '../../utils/store-util';

const actions = {
  setItems({ commit }, query = { }) {
    commit('SET_LOADING', true);
    commit('SET_QUERY', query);
    api.getData('/api/user_files', query)
      .catch((err) => console.log(err))
      .then((res) => {
        commit('SET_ITEMS', res.data['hydra:member']);
        commitPagination(commit, res.data['hydra:member'], res.data['hydra:totalItems'], query);
        commit('SET_TOTAL', res.data['hydra:totalItems']);
        commit('SET_LOADING', false);
      });
  },
  downloadFileUser({ commit }, id) {
    api.downloadFile(`/api/download-file/${id}`)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `${id}-quittance.pdf`,
        );
        document.body.appendChild(link);
        link.click();
        commit('setLoading', { loading: false });
        return res.data;
      })
      .catch(() => {
        commit(
          'notice/setNotice',
          {
            text: `${api.errorMessage}`,
            color: 'error',
          },
          { root: true },
        );
      });
  },
};

const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ITEMS(state, userList) {
    state.items = userList;
  },
  SET_TOTAL(state, total) {
    state.total = total;
  },
  SET_PAGINATION(state, pagination) {
    state.pagination = pagination;
  },
  SET_QUERY(state, query) {
    state.query = query;
  },
};
const state = {
  headers: DEFAULT_FILE_USER_HEADER,
  query: {},
  total: 0,
  noDataMessage: '',
  pagination: {},
  pages: 0,
  loading: false,
  items: [],
  item: null,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
