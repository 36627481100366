<template>
  <v-container class="pa-0 container" fluid>
    <NavbarUser/>
    <v-row class="row-container">
      <v-col cols="12" class="pa-10">
        <base-page-title v-bind:page-title="pageTitle"/>
        <v-row>
          <v-col cols="6" class="pa-6">
          </v-col>
          <v-col cols="6">
          </v-col>
        </v-row>
        <base-user-file-list :items="this.items" :pagination="pagination"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import NavbarUser from '../components/User/NavbarUser.vue';
import BasePageTitle from '../components/BasePageTitle.vue';
import FILE_USER_LIST from '../mock/userMockList';
import BaseUserFileList from '../components/User/BaseUserFileList.vue';

export default {
  name: 'UserView',
  components: {
    BaseUserFileList,
    BasePageTitle,
    NavbarUser,
  },
  data: () => ({
    pageTitle: 'Vos quittances',
  }),
  computed: {
    ...mapState({
      items: (state) => state.fileUser.items,
      pagination: (state) => state.fileUser.pagination,
    }),
  },
  methods: {
    ...mapActions([
      'fileUser/setItems',
    ]),
  },
  created() {
    this['fileUser/setItems'](FILE_USER_LIST);
  },
};
</script>

<style scoped>
.container {
  height: 95vh;
}
.row-container{
  height: 95vh;
  display: flex;
}
</style>
