export const DEFAULT_FILE_USER_HEADER = [
  {
    text: 'N°',
    sortable: false,
    value: 'id',
  },
  {
    text: 'Numero Locataire',
    value: 'ownerAccountNumber',
    sortable: false,
  },
  {
    text: 'Nom du document',
    value: 'fileName',
    sortable: false,
  },
  {
    text: 'Periode',
    value: 'createdAt',
    sortable: false,
  },
  {
    text: 'Action',
    value: 'action',
    sortable: false,
  },
];
