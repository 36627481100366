const actions = {
  sendNotice({ commit, notice }) {
    commit('setNotice', notice);
  },
  closeNotice({ commit }) {
    commit('unsetNotice');
  },
};

const mutations = {
  setNotice(state, notice) {
    state.snackbar = true;
    state.color = notice.color;
    state.text = notice.text;
  },
  unsetNotice(state) {
    state.snackbar = false;
    state.color = 'info';
    state.text = '';
  },
};

const state = {
  snackbar: false,
  color: 'info',
  timeout: 6000,
  text: '',
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
