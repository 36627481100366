<template>
  <v-card
    elevation="0"
    class="mx-auto my-8 login-card"
  >
    <p>pour télécharger votre quittance, veuillez-vous reconnecter </p>
    <v-divider/>
    <v-card-text>
      <v-row align="center" justify="center">
        <v-col cols="2" ><v-icon>mdi-account</v-icon></v-col>
        <v-col cols="10"><p class="text-card ma-0">IDENTIFIANT</p></v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="formLogin.login"
            label="Login"
            placeholder="Login"
            :rules="loginRules"
            outlined
            type="email"
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <div class="password">
        <v-row align="center" justify="center">
          <v-col cols="2" ><v-icon>mdi-key</v-icon></v-col>
          <v-col cols="10"><p class="text-card ma-0">MOT DE PASSE</p></v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="formLogin.password"
              label="Mot de passe"
              :rules="passwordRules"
              placeholder="Mot de passe"
              outlined
              type="password"
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
      <v-progress-circular
        :size="70"
        :width="7"
        v-if="loading"
        color="rgb(200, 211, 0)"
        indeterminate
      ></v-progress-circular>
    <v-card-actions>
      <v-row align="center">
        <v-col cols="12">
          <v-btn
            large
            class="black--text login-btn"
            :disabled="isDisabled"
            @click="submitLogin"
          >
            SE CONNECTER
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
    <v-divider/>
  </v-card>

</template>

<script>
import { mapActions, mapState } from 'vuex';
import FormLogin from '../../models/formLogin';

export default {
  name: 'LoginCard',
  data: () => ({
    formLogin: new FormLogin(),
    loginRules: [
      (v) => !!v || 'Le login est votre numéro de locataire à 5 chiffres',
    ],
    passwordRules: [
      (v) => !!v || 'Le mot de passe est obligatoire',
    ],
  }),
  methods: {
    ...mapActions([
      'user/setUser',
    ]),
    submitLogin() {
      this['user/setUser']({ username: this.formLogin.login, password: this.formLogin.password });
    },
  },
  computed: {
    ...mapState({
      loading: (state) => state.user.loading,
    }),
    isDisabled() {
      return !!(this.formLogin.login === '' || this.formLogin.password === '');
    },
  },
};
</script>

<style scoped>
.text-card{
  display: flex;
  justify-content: start;
  text-transform: uppercase;
  color: rgb(87, 87, 86);
  font-size: 1.2em;
}

.login-card{
  width: 500px;
}

.v-btn:hover:before,
.v-btn:focus:before {
  color: rgb(200, 211, 0);
}
</style>
