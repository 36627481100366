<template>
  <v-container class="pa-0 container" fluid>
    <NavbarAdmin/>
    <v-row class="row-container">
      <v-col cols="12" class="pa-10">
        <base-page-title v-bind:page-title="pageTitle"/>
        <base-admin-file-list
          :items="items"
          :pagination="pagination"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import NavbarAdmin from '../components/admin/NavbarAdmin.vue';
import BasePageTitle from '../components/BasePageTitle.vue';
import BaseAdminFileList from '../components/admin/BaseAdminFileList.vue';

export default {
  name: 'adminShowFile',
  components: { BaseAdminFileList, NavbarAdmin, BasePageTitle },
  data: () => ({
    pageTitle: 'Liste des fichiers',
  }),
  computed: {
    ...mapState({
      headers: (state) => state.fileUser.headers,
      items: (state) => state.fileUser.items,
      pagination: (state) => state.fileUser.pagination,
    }),
  },
  methods: {
    ...mapActions([
      'customer/setItems',
    ]),
  },
};
</script>

<style scoped>

</style>
