<template>
 <div class="nav">
   <v-app-bar
     color="rgb(165, 36, 39)"
     dark
   >
     <v-app-bar-nav-icon @click="drawer = true"/>
       <v-img
         class="mx-2"
         :src="require('../../assets/logo_SSDH_HD_BLANC_PNG.png')"
         max-height="150"
         max-width="150"
         contain
       ></v-img>
     <v-spacer/>
     <v-btn
       icon
       @click="logout"
     >
       <v-icon>mdi-export</v-icon>
     </v-btn>
   </v-app-bar>

   <v-navigation-drawer
     v-model="drawer"
     absolute
     temporary
   >
     <v-list
       nav
       dense
     >
       <v-list-item-group
         v-model="group"
         active-class="deep-purple--text text--accent-4"
       >
         <v-list-item @click="goToPath('admin')">
           <v-list-item-icon>
             <v-icon>mdi-account</v-icon>
           </v-list-item-icon>
           <v-list-item-title>Liste utilisateur</v-list-item-title>
         </v-list-item>
         <v-list-item @click="goToPath('admin-files')">
           <v-list-item-icon>
             <v-icon>mdi-file-multiple</v-icon>
           </v-list-item-icon>
           <v-list-item-title>Liste des fichiers</v-list-item-title>
         </v-list-item>
       </v-list-item-group>
     </v-list>
   </v-navigation-drawer>
 </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'NavbarAdmin',
  data: () => ({
    drawer: false,
    group: null,
  }),
  methods: {
    goToPath(path) {
      this.$router.push({ name: path });
    },
    ...mapActions([
      'user/logout',
    ]),
    logout() {
      this['user/logout']();
      this.$router.push('/');
    },
  },
};
</script>

<style scoped>
.nav{
  width: 100%;
}
</style>
