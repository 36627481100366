import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import AdminHome from '../views/AdminHome.vue';
import AdminFiles from '../views/AdminFiles.vue';
import UserView from '../views/UserView.vue';
import store from '../store/index';

Vue.use(VueRouter);

const requireAuth = (to, from, next) => {
  if (store.state.user.token) {
    next();
  } else {
    next('/');
  }
};

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/admin',
    name: 'admin',
    beforeEnter: requireAuth,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: AdminHome,
  },
  {
    path: '/admin-files',
    name: 'admin-files',
    beforeEnter: requireAuth,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: AdminFiles,
  },
  {
    path: '/user-file',
    name: 'user-view',
    beforeEnter: requireAuth,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: UserView,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    next('/404');
  } else {
    if (to.name.includes('admin')) {
      if (store.state.user.isAdmin) {
        next();
      } else {
        next('/');
      }
    }
    next();
  }
});
export default router;
