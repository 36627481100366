import jwtDecode from 'jwt-decode';
import Router from '../../router';
import api from '../../utils/api';

const state = {
  loading: false,
  token: null,
  isAdmin: false,
};

const actions = {
  // eslint-disable-next-line no-unused-vars
  setUser({ commit }, user) {
    commit('SET_LOADING', true);
    api.postData('/api/login_check', user)
      .then((res) => {
        commit('SET_USER', res.data.token);
        commit('SET_LOADING', false);
        commit('SET_IS_ADMIN', res.data);
      })
      .catch(() => {
        commit(
          'notice/setNotice',
          {
            text: 'Login ou Mot de passe Faux',
            color: 'error  ',
          },
          { root: true },
        );
        commit('SET_LOADING', false);
      });
    // commit('SET_USER', user);
  },
  logout({ commit }) {
    commit('SET_LOGOUT');
  },
};

const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  async SET_IS_ADMIN(state, token) {
    const data = jwtDecode(token.token);
    state.isAdmin = data.roles.find((role) => role === 'ROLE_ADMIN') === 'ROLE_ADMIN';
    await Router.push({
      name: state.isAdmin === true ? 'admin' : 'user-view',
    });
  },
  SET_USER(state, token) {
    state.token = token;
  },

  SET_LOGOUT(state) {
    state.token = null;
    state.isAdmin = null;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
