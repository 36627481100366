import Vue from 'vue';
import FileUpload from 'vue-upload-component';

import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import filters from './filters/index';

Vue.config.productionTip = false;
Vue.use(filters);

Vue.component('file-upload', FileUpload);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
