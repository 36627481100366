import cleanDate from './cleanDate';
import isYesOrNo from './isYesOrNo';
import getCurrentMoney from './getCurrentMoney';
import concatSalesmanName from './concatSalesmanName';
import concatCustomerAddress from './concatCustomerAddress';
import sendStatusCommunication from './sendStatusCommunication';
import castProviderStatus from './castProviderStatus';
import formatSalesmanName from './formatSalesmanName';
import formatFileSize from './formatFileSize';

export default {
  install(Vue) {
    Vue.filter('cleanDate', cleanDate);
    Vue.filter('isYesOrNO', isYesOrNo);
    Vue.filter('getCurrentMoney', getCurrentMoney);
    Vue.filter('concatSalesmanName', concatSalesmanName);
    Vue.filter('concatCustomerAddress', concatCustomerAddress);
    Vue.filter('sendStatusCommunication', sendStatusCommunication);
    Vue.filter('castProviderStatus', castProviderStatus);
    Vue.filter('formatSalesmanName', formatSalesmanName);
    Vue.filter('formatFileSize', formatFileSize);
  },
};
