export const DEFAULT_ROW_PER_PAGE = 30;

export function getDefaultPagination() {
  return {
    pages: 0,
  };
}

/**
 * Set pagination to the products store
 * @param {*} commit -- commit funciton pass from caller
 * @param {*} page -- current page number
 * @param {*} totalItems  -- total amount of items
 * @param {*} rowsPerPage -- rows to display per pages
 * @param {*} pages -- total pages
 */
export function setPagination(commit, page, totalItems, pages, rowsPerPage) {
  commit('setPagination', {
    page,
    totalItems,
    pages,
    rowsPerPage: rowsPerPage || DEFAULT_ROW_PER_PAGE,
  });
}

/**
 * Set pagination to the products store
 * @param {*} commit -- commit funciton pass from caller
 * @param {*} items -- search result
 */
export function commitPagination(commit, items, totalItems, query) {
  const rowsPerPage = query.itemsPerPage ? query.itemsPerPage : DEFAULT_ROW_PER_PAGE;
  const pages = Math.ceil(totalItems / rowsPerPage);
  commit('SET_PAGINATION', {
    pages,
  });
}
