<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo_SSDH_HD_CMJN_PNG.png')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>
      <v-col class="ma-0 pa-0"></v-col>
      <v-col
        class="mb-5"
        cols="12"
      >
        <v-row justify="center">
          <LoginCard/>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoginCard from '../components/login/LoginCard.vue';

export default {
  name: 'Home',
  components: {
    LoginCard,
  },
};
</script>
