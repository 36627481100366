import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
// eslint-disable-next-line import/no-cycle
import user from './modules/user';
import notice from './modules/notice';
import fileUser from './modules/fileUser';
import customer from './modules/customer';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState({ storage: window.sessionStorage })],
  modules: {
    user,
    notice,
    fileUser,
    customer,
  },
});
