import DEFAULT_CUSTOMER_HEADER from '../../models/customerHeader';
import api from '../../utils/api';
import { commitPagination, getDefaultPagination } from '../../utils/store-util';

const actions = {
  setItems({ commit }, query = { }) {
    commit('SET_LOADING', true);
    commit('SET_QUERY', query);
    api.getData('/api/basic_users', query)
      .catch((err) => console.log(err))
      .then((res) => {
        console.log(res);
        commit('SET_ITEMS', res.data['hydra:member']);
        commitPagination(commit, res.data['hydra:member'], res.data['hydra:totalItems'], query);
        commit('SET_TOTAL', res.data['hydra:totalItems']);
        commit('SET_LOADING', false);
      });
    commit('SET_LOADING', true);
  },
  sendCsv({ commit }, file) {
    commit('SET_LOADING', true);
    const data = new FormData();
    data.append('csv', file.file);
    api.uploadloadFiles('/api/import-users', data)
      .then(() => {
        commit('SET_LOADING', false);
        commit(
          'notice/setNotice',
          {
            text: 'Utilisateur sauvegardé',
            color: 'info',
          },
          { root: true },
        );
      })
      .catch(() => {
        commit(
          'notice/setNotice',
          {
            text: 'Login ou Mot de passe Faux',
            color: 'error  ',
          },
          { root: true },
        );
      });
  },
};

const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ITEMS(state, userList) {
    state.items = userList;
  },
  SET_TOTAL(state, total) {
    state.total = total;
  },
  SET_PAGINATION(state, pagination) {
    state.pagination = pagination;
  },
  SET_QUERY(state, query) {
    state.query = query;
  },
};

const state = {
  headers: DEFAULT_CUSTOMER_HEADER,
  query: {},
  total: 0,
  noDataMessage: '',
  pages: 0,
  loading: false,
  items: [],
  item: null,
  pagination: getDefaultPagination(),
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
