<template>
  <v-card elevation="2">
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :page.sync="pagination.page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:item.action="{ }">
        <v-btn
          icon
          color="rgb(200, 211, 0)"
        >
          <v-icon>mdi-upload</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :total-visible="8"
        color="rgb(165, 36, 39)"
        :length="pagination.pages"
      ></v-pagination>
    </div>
  </v-card>
</template>

<script>
import { debounce } from 'lodash';
import { mapActions, mapState } from 'vuex';
import { SEARCH_DEBOUNCE_WAIT_TIMER } from '../../utils/constants';
import { DEFAULT_CUSTOMER_HEADER } from '../../models/customerHeader';

const DEFAULT_ROWS_PER_PAGE = 30;

export default {
  name: 'BaseAdminCustomerList',
  data: () => ({
    page: 1,
    headers: DEFAULT_CUSTOMER_HEADER,
    itemsPerPage: DEFAULT_ROWS_PER_PAGE,
    query: {
      itemsPerPage: DEFAULT_ROWS_PER_PAGE,
    },
  }),
  props: {
    items: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions([
      'customer/setItems',
    ]),
    delaySearch: debounce(function search() {
      this.buildQuery();
      this['customer/setItems'](this.query);
    }, SEARCH_DEBOUNCE_WAIT_TIMER),
    buildQuery() {
      this.query.page = this.page;
    },
  },
  computed: {
    ...mapState({
      loading: (state) => state.customer.loading,
    }),
  },
  watch: {
    page: {
      handler() {
        this.delaySearch();
      },
      deep: true,
    },
  },
  created() {
    this.delaySearch();
  },
};
</script>

<style scoped>

</style>
